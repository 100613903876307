<template>
  <Card title="recovery_password">
    <v-form ref="form">
      <v-row dense>
        <v-col>
          {{$t('message.recovery_password_message')}}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field
            dense
            v-model="email"
            outlined
            background-color="white"
            :rules="[rules.required]"
            color="var(--color-accent)"
            :placeholder="$t('message.email')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-2" justify="center">
        <v-btn rounded @click="recoveryPassword"
          >{{ $t("button.recovery_password") }}
        </v-btn>
      </v-row>
    </v-form>
  </Card>
</template>

<script>
import { mapActions } from "vuex";
import Card from "@/components/Card.vue";

export default {
  name: "RecoveryPassword",
  components: {
    Card,
  },
  data() {
    return {
      email: null,
      rules: {
        required: (v) => !!v || this.$t("message.required"),
      },
    };
  },
  methods: {
    recoveryPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.sendPasswordResetEmail(this.email);
    },
    ...mapActions(["sendPasswordResetEmail"]),
  },
};
</script>